import { AUTH_CONST } from '@store/constant';
import LocalStore from "@utils/localStorage";

let user = JSON.parse(LocalStore.get('user'));
let token = LocalStore.get('token');
const initialState = (token !== "" && token !== undefined ) ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case AUTH_CONST.LOGIN_REQUEST:
            return {
                loggedIn: true,
                user: action.user
            };

        case AUTH_CONST.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };

        case AUTH_CONST.LOGIN_FAILURE:
            return {};

        case AUTH_CONST.GET_PROFILE_REQUEST:
            return {
                loggedIn: true,
            };
        case AUTH_CONST.GET_PROFILE_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };
        case AUTH_CONST.GET_PROFILE_FAILURE:
            return {
                loggedIn: false,
                user: null
            };
        case AUTH_CONST.LOGOUT:
            return {};
        default:
            return state
    }
}