class LocalStore {
  static set(key, data) {
    if (!process.browser || !data) return;
    try {
      localStorage.setItem(key, JSON.stringify(data));
      return true;
    } catch (e) {
      console.error('Localstorage is full:', { key, data }, e);
    }
  }

  static get(key) {
    if (!process.browser) return;
    const data = localStorage.getItem(key);
    if (data === 'undefined') {
      LocalStore.rm(key);
      return;
    }
    if (data) return JSON.parse(data);
    return data;
  }

  static rm(key) {
    if (!process.browser) return;
    localStorage.removeItem(key);
  }

  static clear() {
    if (!process.browser) return;
    localStorage.clear();
  }
}

export default LocalStore;
