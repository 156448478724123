const DEMO = {
    BLANK_LINK: "#!",
};

export const LAYOUT = {
    COLLAPSE_MENU: 'COLLAPSE_MENU',
    COLLAPSE_TOGGLE: 'COLLAPSE_TOGGLE',
    FULL_SCREEN: 'FULL_SCREEN',
    FULL_SCREEN_EXIT: 'FULL_SCREEN_EXIT',
    CHANGE_LAYOUT: 'CHANGE_LAYOUT',
    NAV_CONTENT_LEAVE: 'NAV_CONTENT_LEAVE',
    NAV_COLLAPSE_LEAVE: 'NAV_COLLAPSE_LEAVE',
};

export const AUTH_CONST = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    AUTH_LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
    AUTH_LOGIN_FAILURE: 'AUTH_LOGIN_FAILURE',
    LOGOUT: 'AUTH_LOGOUT',

    GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',
};

export default DEMO;